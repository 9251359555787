.materials {
    &__wrapper {
        background: $light; }
    &__content {}
    &__items {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
        @media all and (max-width: 1255px) {
            justify-content: space-around; } }
    &__item {
        flex: 0 0 32%;
        min-width: 345px;
        margin-right: 5px;
        &:last-child {
            margin-right: 0; }
        @media all and (max-width: 1255px) {
            margin-bottom: 30px;
            flex: 0 0 40%;
            &:last-child {
                margin-bottom: 0; } }
        @media all and (max-width: 840px) {
            flex: 0 0 70%; }
        @media all and (max-width: 500px) {
            flex: 0 0 100%; } }

    &__img {
        width: 100%;
        height: 215px;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background: linear-gradient(180deg, rgba(0, 33, 60, 0.5) 0%, rgba(255, 255, 255, 0) 100%); }
        h3 {
            position: relative;
            z-index: 1; } }
    &__text-block {
        width: calc(100% - 30px);
        height: auto;
        min-height: 193px;
        background: #fff;
        margin-top: -30px;
        padding-bottom: 10px;
        position: relative;
        li {
            color: $dark_blue;
            position: relative;
            margin-bottom: 15px;
            padding-left: 17px;
            font-size: 15px;
            &:before {
                content: '•';
                font-size: 32px;
                display: inline-block;
                width: 7px;
                height: 7px;
                margin-right: 10px;
                color: $orange;
                line-height: 1px;
                position: absolute;
                top: calculateHeight(9);
                left: 0;
                @media all and (max-width: 1200px) {
                    top: calculateHeight(7); } } } } }




