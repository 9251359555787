header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    z-index: $zIndexFirst;
    transition: transform .3s, opacity 1s;
    @media screen and ( max-width: 768px ) {
        align-items: center;
        padding-bottom: tH(100); }
    nav {
        ul {
            li {
                text-align: right;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                position: relative;
                &:not(:last-child) {
                    margin-bottom: 10px;
                    &:hover {
                        a {
                            color: $orange; } } }
                a {
                    color: $white;
                    margin-right: calculateWidth(5); }
                img {
                    transition: filter .3s;
                    width: calculateWidth(15); }
                ul.language {
                    flex-grow: 0;
                    position: absolute;
                    top: 0;
                    &:hover {
                        li {
                            display: flex; } }
                    li {
                        display: none;
                        justify-content: flex-end;
                        align-items: center;
                        &:hover {
                            a {
                                color: $orange; } }
                        a {
                            color: $light_gray; }
                        &:first-child {
                            display: flex;
                            img {
                                opacity: 1; } }
                        img {
                            opacity: 0;
                            width: calculateWidth(10); } } } } } }
    #burger {
        .burger__wrapper {
            &-bottom {
                @media screen and ( min-width: 768px ) {
                    display: none; } } }
        @media screen and ( max-width: 768px ) {
            position: relative;
            width: 37px;
            height: 30px;
            cursor: pointer;
            border-top: 3px solid $white;
            border-bottom: 3px solid $white;
            &::after {
                position: absolute;
                content: ' ';
                top: 50%;
                margin-top: -1.5px;
                height: 3px;
                width: 100%;
                background-color: $white; }
            .burger__wrapper {
                position: fixed;
                height: 100%;
                width: 100%;
                background: $dark_blue;
                top: 0;
                right: 0;
                bottom: 0;
                max-width: 75%;
                transform: translate(100%);
                transition: transform .3s;
                z-index: $zIndexVeryBig;
                padding: 40px 20px 100px 0px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                min-height: 100vh;
                &-top {
                    #cross {
                        width: 30px;
                        height: 30px;
                        position: relative;
                        margin-left: auto;
                        &::after,
                        &::before {
                            position: absolute;
                            content: ' ';
                            height: 3px;
                            width: 100%;
                            background-color: $white;
                            transform: rotate(45deg);
                            top: 50%; }
                        &::before {
                            transform: rotate(-45deg); } }
                    ul {
                        margin-top: 60px;
                        li {
                            a {
                                color: $white;
                                @media screen and ( max-width: 500px ) {
                                    font-size: 18px; } }
                            img {
                                width: 15px;
                                height: 15px;
                                margin-left: 10px; }
                            .language {
                                margin-top: initial;
                                li {
                                    img {
                                        width: 10px; } } } } } }
                &-bottom {
                    .social-list {
                        justify-content: flex-end; }
                    ul {
                        margin-top: 50px;
                        li {
                            a {
                                font-size: 18px; } } } } } } } }



.header__scroll {
    background-color: $white;
    position: fixed;
    left: 0;
    top: 0;
    align-items: center;
    padding: calculateHeight(10) calculateWidth(120);
    max-height: 100px;
    transition: transform .3s, opacity 1s, box-shadow .3s;
    z-index: 9;
    &:hover {
        box-shadow: $main_shadow;
        transform: translate(0px, 0px) !important; }
    @media screen and ( max-width: 500px ) {
        padding: 10px 20px; }
    .logo {
        height: 100%;
        display: flex;
        align-items: center;
        svg {
            --color-logo: #00213C; } }
    #burger {
        @media screen and ( max-width: 768px ) {
            border-top: 3px solid $dark_blue;
            border-bottom: 3px solid $dark_blue;
            &::after {
                background-color: $dark_blue; } }
        .burger__wrapper {
            .burger__wrapper-top {
                @media screen and ( min-width: 768px ) {
                    ul {
                        display: flex;
                        li {
                            margin-left: 50px;
                            margin-bottom: initial;
                            &:last-child {
                                display: none; }
                            a {
                                color: $dark_blue;
                                &:hover {
                                    color: $orange; } } } } } } } } }
