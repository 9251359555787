*,
*::before,
*::after {
  box-sizing: border-box; }

ul,
ol {
  padding: 0; }

body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0; }

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5; }

ul,
ol, {
  list-style: none; }

a {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent; }

img {
  max-width: 100%;
  display: block; }

article > * + * {
  margin-top: 1em; }

input,
button,
textarea,
select {
  font: inherit; }

input {
  border: initial;
  outline: initial; }

.slick-list {
  &:focus {
    outline: none; } }
.slick-slide {
  &:active {
      outline: none; }
  &:focus {
      outline: none; } }
