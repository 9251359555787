.banner {
  width: 100%;
  height: 70px;
  background: $orange;
  overflow-x: hidden;
  white-space: nowrap;
  &__text {
    margin: 0;
    line-height: 70px;
    display: inline-block;
    padding-left: 100%;
    animation: marquee 12s linear infinite;
    font-size: 17px;
    font-weight: 300;
    &:before {
      content: '';
      display: inline-block;
      margin: 0 13px -5px 0;
      width: 25px;
      height: 26px;
      background: url("../img/fire.svg"); } } }


@keyframes marquee {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(-100%, 0); } }
