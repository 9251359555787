@import 'imports/reset.sass';
@import 'imports/functions.sass';
@import 'imports/variables';
@import 'imports/fonts';
@import 'imports/global';

body {
    @import 'components/header';
    @import 'homepage/first_section';
    @import 'homepage/goals';
    @import 'components/clients';
    @import 'components/absolute_lines';
    @import 'components/our_works';
    @import 'components/you_will_get';
    @import 'components/you_would_like';
    @import 'uslugi/services';
    @import 'components/materials';
    @import 'components/callback';
    @import 'components/slider_window';
    @import 'kukhni/kitchen';
    @import 'gostinye/livingRoom';
    @import 'spalni/bedroom';
    @import 'garderobnye/wardrobe';
    @import 'vannye/bath';
    @import 'detskie/childrenRoom';
    @import 'stoly/table';
    @import 'prikhozhaya/hallway';
    @import 'dveri/door';
    @import 'lestniczy/stairs';
    @import 'stenovye-paneli/panels';
    @import 'dlya-biznesa/business';
    @import 'mebelnye-fasady/facades';
    @import 'pokraska/painting';
    @import 'obshivka-mebeli/upholstery';
    @import 'o-nas/aboutUs';
    @import 'kontakty/contacts';
    @import 'homepage/products';
    @import 'homepage/numbers';
    @import 'homepage/choose-us';
    @import 'components/process';
    @import 'homepage/portfolio';
    @import 'components/modal';
    @import 'components/footer';
    @import 'errors/errors';
    @import 'components/banner'; }
