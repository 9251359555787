.aboutUs-first-section {
    &__wrapper {
        background-image: url(../img/aboutUs__bg.png);
        background-attachment: fixed;
        background-position: left;
        background-size: cover; }
    &__content {
        display: grid;
        grid-template-columns: 1fr 3fr;
        grid-template-rows: 1fr;
        @media all and (max-width: 768px) {
            grid-template-columns: 1fr;
            h1 {
                text-align: center; } } } }

