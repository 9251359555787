.choose-us__wrapper {
    background-color: $light;
    position: relative;
    overflow: hidden;
    padding-bottom: calculateHeight(50);
    @media screen and ( max-width: 768px ) {
        padding-bottom: tH(50); }
    @media screen and ( max-width: 500px ) {
        padding-bottom: 50px; }
    .grid-template {
        .border-item {
            border-color: $white;
            @media screen and ( max-width: 768px ) {
                border-right: .5px solid $white; } } }
    .white-bg {
        height: 100%;
        background-color: $white;
        z-index: 0;
        width: calc( 100% + 200px );
        position: absolute;
        bottom: -90%;
        right: -50px;
        transform: rotate(-5deg);
        overflow: hidden;
        .grid-template {
            width: calc(100% - 8.33333vw - 8.33333vw - 200px);
            margin-left: calc( 50px + 100px + 8.33333vw );
            transform: rotate(5deg) translate(0px, -50%);
            @media screen and ( max-width: 500px ) {
                display: none; }
            .border-item {
                border-color: $light; } } }

    .choose-us {
        position: relative;
        @media screen and ( max-width: 500px ) {
            padding-top: initial; }
        .top-section {
            display: flex;
            justify-content: space-between;
            align-items: center;
            a {
                margin-right: calculateWidth(15); } }
        .choose-us__content {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            @media screen and ( max-width: 768px ) {
                flex-wrap: wrap;
                a {
                    margin-top: tH(50); } }
            @media screen and ( max-width: 500px ) {
                a {
                    margin-top: 50px; } }
            .choose-us__item {
                width: 25%;
                @media screen and ( max-width: 768px ) {
                    width: 100%;
                    &:not(:first-child) {
                        margin-top: tH(50); } }
                @media screen and ( max-width: 500px ) {
                    &:not(:first-child) {
                        margin-top: 50px; } }
                p {
                    color: $dark_blue; } } } } }
