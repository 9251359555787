.produce-from {
    &__wrapper {
        background-image: url('../img/produce_from__bg.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: center;
        padding-bottom: 220px;
        @media all and (max-width: 768px) {
            background-position: 45%; } }
    &__heading {
        color: $white;
        border-left-color: $white; }
    &__text {
        max-width: 700px; } }
