.all-services {
    position: relative;
    &__content {
        display: grid;
        grid-template-columns: repeat( 4, 1fr );
        grid-template-rows: 1fr;
        grid-gap: 10px 0;
        @media all and (max-width: 910px) {
            grid-template-columns: repeat( 3, 1fr ); }
        @media all and (max-width: 680px) {
            grid-template-columns: repeat( 2, 1fr ); }
        @media all and (max-width: 450px) {
            grid-template-columns: 1fr; } }
    &__item {
        min-width: 182px;
        position: relative;
        margin-right: 10px;
        .height-equal-width {
            padding-top: 100%; }
        .content {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-size: 100%;
            background-repeat: no-repeat;
            padding-right: 20px;
            transition: background-size .6s, background-position 2s;
            &::before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                display: block;
                background: linear-gradient(180deg, rgba(0, 33, 60, 0.5) 0%, rgba(0, 33, 60, 0) 100%); }
            &:hover {
                transition: background-size .6s;
                z-index: 1;
                background-size: 130%;
                background-position: 30%;
                .all-services__heading {
                    color: $orange;
                    transition: color .6s; } } } }
    &__heading {
        position: relative;
        word-break: break-word;
        &:after {
            content: '';
            width: 20px;
            height: 20px;
            margin-left: 5px;
            display: inline-block;
            background-image: url('../img/orArrow.svg');
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;
            bottom: calculateHeight(8);
            @media all and (max-width: 1200px) {
                bottom: calculateHeight(4); }
            @media all and (max-width: 1000px) {
                bottom: calculateHeight(2); } } } }

