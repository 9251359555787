@charset "UTF-8";
*,
*::before,
*::after {
  box-sizing: border-box; }

ul,
ol {
  padding: 0; }

body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0; }

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5; }

ul,
ol {
  list-style: none; }

a {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent; }

img {
  max-width: 100%;
  display: block; }

article > * + * {
  margin-top: 1em; }

input,
button,
textarea,
select {
  font: inherit; }

input {
  border: initial;
  outline: initial; }

.slick-list:focus {
  outline: none; }

.slick-slide:active {
  outline: none; }

.slick-slide:focus {
  outline: none; }

@font-face {
  font-family: 'Montserrat', sans-serif;
  src: url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap"); }

.h1-font {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 4.16667vw;
  color: #ffffff;
  text-transform: uppercase; }

.h2-font {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 2.77778vw;
  color: #00213C;
  text-transform: uppercase; }

.h3-font {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.73611vw;
  color: #ffffff;
  text-transform: uppercase; }

.h4-font {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.04167vw;
  text-transform: uppercase;
  color: #00213C; }

.main-body {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.25vw;
  color: #ffffff; }

.main-body-small {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.04167vw;
  color: #00213C; }

.bold-link {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.04167vw;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #F7875F; }

.main-link {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.04167vw;
  text-transform: uppercase; }

.small-link {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.76389vw;
  color: #C50000; }

.input-font {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 0.90278vw; }

.color-white {
  color: #ffffff; }

.main-font {
  font-family: 'Montserrat', sans-serif; }

@media screen and (max-width: 768px) {
  .h1-font {
    font-size: 7.8125vw; }
  .h2-font {
    font-size: 5.20833vw; }
  .h3-font {
    font-size: 3.25521vw; }
  .h4-font {
    font-size: 1.95312vw; }
  .main-body {
    font-size: 2.34375vw; }
  .main-body-small {
    font-size: 1.95312vw; }
  .bold-link {
    font-size: 1.95312vw; }
  .main-link {
    font-size: 1.95312vw; }
  .small-link {
    font-size: 1.43229vw; }
  .input-font {
    font-size: 12px; } }

@media screen and (max-width: 500px) {
  .h1-font {
    font-size: 35px; }
  .h2-font {
    font-size: 30px; }
  .h3-font {
    font-size: 17px; }
  .h4-font {
    font-size: 13px; }
  .main-body {
    font-size: 16px; }
  .main-body-small {
    font-size: 15px; }
  .bold-link {
    font-size: 13px; }
  .main-link {
    font-size: 14px; }
  .small-link {
    font-size: 11px; } }

.p25 {
  padding: 25px; }

.p15 {
  padding: 15px; }

.pt350 {
  padding-top: 38.88889vh; }

.pt250 {
  padding-top: 27.77778vh; }

.pt150 {
  padding-top: 16.66667vh; }

.pt95 {
  padding-top: 10.55556vh; }

.pt100 {
  padding-top: 11.11111vh; }

.pb100 {
  padding-bottom: 11.11111vh; }

.pb170 {
  padding-bottom: 18.88889vh; }

.pb175 {
  padding-bottom: 19.44444vh; }

.pt70 {
  padding-top: 7.77778vh; }

.pt50 {
  padding-top: 5.55556vh; }

.pt30 {
  padding-top: 3.33333vh; }

.pt15 {
  padding-top: 1.66667vh; }

.pt10 {
  padding-top: 1.11111vh; }

.pb250 {
  padding-bottom: 27.77778vh; }

.pb150 {
  padding-bottom: 16.66667vh; }

.pb45 {
  padding-bottom: 5vh; }

.pl120 {
  padding-left: 8.33333vw; }

.pl30 {
  padding-left: 2.08333vw; }

.pl20 {
  padding-left: 1.38889vw; }

.pl12 {
  padding-left: 0.83333vw; }

.btn-padding {
  padding: 2.22222vh 3.81944vw; }

.small-btn-padding {
  padding: 1.77778vh 1.73611vw; }

.main-padding {
  padding-left: 8.33333vw;
  padding-right: 8.33333vw; }

.mt150 {
  margin-top: 16.66667vh; }

.mt130 {
  margin-top: 130px; }

.mt120 {
  margin-top: 13.33333vh; }

.main-y-padding {
  padding-top: 11.11111vh;
  padding-bottom: 16.66667vh; }

.mt70 {
  margin-top: 7.77778vh; }

.mb165 {
  margin-bottom: 18.33333vh; }

.mb70 {
  margin-bottom: 7.77778vh; }

.mb50 {
  margin-bottom: 5.55556vh; }

.mb40 {
  margin-bottom: 4.44444vh; }

.mb30 {
  margin-bottom: 3.33333vh; }

.mb25 {
  margin-bottom: 2.77778vh; }

.mb20 {
  margin-bottom: 2.22222vh; }

.mb15 {
  margin-bottom: 1.66667vh; }

.mt50 {
  margin-top: 5.55556vh; }

.mt30 {
  margin-top: 3.33333vh; }

.mt20 {
  margin-top: 2.22222vh; }

.mr20 {
  margin-right: 1.38889vw; }

.mr10 {
  margin-right: 0.69444vw; }

.icon-cross {
  position: relative;
  width: 1.94444vw;
  height: 1.94444vw; }
  .icon-cross::before, .icon-cross::after {
    content: ' ';
    position: absolute;
    height: 1.5px;
    width: 100%;
    bottom: 0;
    margin-bottom: 0.97222vw;
    left: 0;
    transform: rotate(45deg);
    background-color: #00213C; }
  .icon-cross::before {
    transform: rotate(-45deg); }

.opacity1 {
  opacity: 1 !important; }

.error_message {
  position: absolute;
  top: -25px;
  background: #00213C;
  border: 1px solid #ffffff;
  max-width: 100%;
  padding: 5px 10px;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  z-index: 9999;
  opacity: 0;
  transition: opacity .3s; }
  .error_message p {
    width: 100% !important;
    margin: initial !important;
    font-weight: normal;
    text-align: center;
    color: #ffffff; }
  .error_message::after {
    content: '';
    position: absolute;
    bottom: -10px;
    background: #00213C;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 10px;
    height: 10px;
    border-right: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff; }

a {
  transition: color .3s; }

svg {
  --color-logo: #ffffff; }

.active-link {
  text-decoration: underline;
  color: #F7875F !important;
  position: relative; }
  .active-link::after {
    position: absolute;
    right: -1.04167vw;
    width: 1.11111vw;
    bottom: -3px; }
  .active-link:hover {
    color: #F67A4E !important; }

.link-hover {
  color: #F7875F;
  font-weight: bold; }
  .link-hover:hover {
    color: #F67A4E !important;
    text-decoration: underline; }

.text-center {
  text-align: center; }

.center {
  margin-left: auto;
  margin-right: auto; }

.text-darkBlue {
  color: #00213C; }

.logo {
  width: 8.68056vw; }

.underline {
  text-decoration: underline; }

.border-right {
  border-right: 0.5px solid #14344F; }

.border-left {
  border-left: 0.5px solid #14344F; }

.border-big {
  border-width: 1px; }

.border-biggest {
  border-width: 1.5px; }

.border-right-d {
  border-right: 0.5px dashed #E9EFF6; }

.border-left-d {
  border-left: 0.5px dashed #E9EFF6; }

.check {
  padding-left: 1.38889vw;
  position: relative; }
  .check::after {
    content: url(../img/check.svg);
    position: absolute;
    left: 0;
    width: 1.11111vw; }

.border-left-light {
  border-left: 0.5px solid #ffffff; }

.border-right-light {
  border-right: 0.5px solid #ffffff; }

.social-list {
  display: flex; }
  .social-list a {
    display: block;
    width: 3.47222vw; }
    .social-list a img {
      height: auto;
      width: 100%; }

.social-list.scrolled {
  position: fixed;
  bottom: 30px;
  left: 8.33333vw; }

.heading-arrow {
  position: relative; }
  .heading-arrow:after {
    content: '';
    width: 30px;
    height: 30px;
    display: inline-block;
    background-image: url("../img/orArrow.svg");
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    margin-left: 10px; }
    @media all and (max-width: 1000px) {
      .heading-arrow:after {
        width: 24px;
        height: 24px; } }
  .heading-arrow:hover {
    color: #F7875F; }

.heading-check {
  position: relative; }
  .heading-check:before {
    content: '';
    width: 18px;
    height: 13px;
    display: inline-block;
    background-image: url("../img/check.svg");
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    left: -22px;
    margin-top: 0.66667vh; }
    @media all and (max-width: 1440px) {
      .heading-check:before {
        margin-top: 0.44444vh; } }
    @media all and (max-width: 1000px) {
      .heading-check:before {
        width: 14px;
        height: 10px; } }

.rounded-button {
  width: 50px;
  height: 50px;
  background: #F7875F;
  border-radius: 50%;
  position: relative;
  cursor: pointer; }
  .rounded-button:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background-image: url("../img/arrow.svg");
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto; }
  .rounded-button--left:after {
    transform: rotate(-135deg); }
  .rounded-button--right:after {
    transform: rotate(45deg); }
  .rounded-button:hover {
    background: #F67A4E; }

.border-initial {
  border: initial !important; }

.input-default {
  padding: 1.33333vh 1.73611vw 1.33333vh 3.47222vw;
  background: #ffffff;
  border-radius: 50px;
  color: #00213C;
  border: 1px solid black; }
  .input-default:hover {
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.01); }
  .input-default:active {
    border: 1px solid #00213C; }

.input-error {
  border: 1px solid #C50000;
  color: #C50000; }

input::placeholder {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #00213C; }

.subm {
  text-align: center;
  background: #F7875F;
  border-radius: 50px;
  color: #ffffff;
  outline: none;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  display: inline-flex;
  justify-content: center;
  height: 52px;
  line-height: 1;
  align-items: center;
  transition: background .3s, color .3s;
  border: 1px solid #F7875F; }
  .subm img {
    width: 1.04167vw;
    height: 0.97222vw;
    margin-left: 5px; }
  .subm svg {
    width: 1.04167vw;
    height: 0.97222vw;
    margin-left: 5px; }
    .subm svg path {
      transition: .3s; }
  .subm:hover {
    background: #F67A4E; }
  @media screen and (max-width: 1200px) {
    .subm {
      font-size: 12px; }
      .subm svg {
        width: 1.95312vw;
        height: 1.36719vh; } }
  @media screen and (max-width: 768px) {
    .subm svg {
      width: 1.95312vw;
      height: 1.36719vh; } }
  @media screen and (max-width: 600px) {
    .subm {
      padding: 20px; }
      .subm svg {
        width: 15px;
        height: 15px; } }

.inverted_button svg path {
  stroke: #F7875F; }

.inverted_button:hover svg path {
  stroke: #ffffff; }

label {
  font-size: 0; }

button {
  text-transform: uppercase; }

.gray-bg {
  position: absolute;
  width: 100%;
  height: 50%;
  bottom: 0;
  left: 0;
  background-color: #E9EFF6;
  z-index: -1; }

.grid-template {
  height: 100%;
  width: calc(100% - 8.33333vw - 8.33333vw);
  position: absolute;
  display: grid;
  grid-template-columns: repeat(4, 1fr); }
  @media screen and (max-width: 768px) {
    .grid-template {
      grid-template-columns: repeat(2, 1fr); } }

@media screen and (max-width: 768px) {
  .pt350 {
    padding-top: 34.17969vh; }
  .pt250 {
    padding-top: 24.41406vh; }
  .pt150 {
    padding-top: 14.64844vh; }
  .pt100 {
    padding-top: 9.76562vh; }
  .pt95 {
    padding-top: 9.27734vh; }
  .pb100 {
    padding-bottom: 9.76562vh; }
  .pb170 {
    padding-bottom: 16.60156vh; }
  .pb175 {
    padding-bottom: 17.08984vh; }
  .pt70 {
    padding-top: 6.83594vh; }
  .pt50 {
    padding-top: 4.88281vh; }
  .pt30 {
    padding-top: 2.92969vh; }
  .pt15 {
    padding-top: 1.46484vh; }
  .pt10 {
    padding-top: 0.97656vh; }
  .pb45 {
    padding-bottom: 4.39453vh; }
  .pb250 {
    padding-bottom: 24.41406vh; }
  .pb150 {
    padding-bottom: 14.64844vh; }
  .pl30 {
    padding-left: 3.90625vw; }
  .pl20 {
    padding-left: 2.60417vw; }
  .pl12 {
    padding-left: 1.5625vw; }
  .btn-padding {
    padding: 1.95312vh 7.16146vw; }
  .icon-cross {
    width: 3.64583vw;
    height: 3.64583vw; }
    .icon-cross::before, .icon-cross::after {
      margin-bottom: 1.82292vw; }
  .check {
    padding-left: 2.60417vw; }
    .check::after {
      width: 2.08333vw; }
  .active-link::after {
    width: 2.08333vw;
    right: -1.95312vw; }
  .mt150 {
    margin-top: 14.64844vh; }
  .mt120 {
    margin-top: 11.71875vh; }
  .mb165 {
    margin-bottom: 16.11328vh; }
  .mt70 {
    margin-top: 6.83594vh; }
  .mb70 {
    margin-bottom: 6.83594vh; }
  .mb50 {
    margin-bottom: 4.88281vh; }
  .mb40 {
    margin-bottom: 3.90625vh; }
  .mb30 {
    margin-bottom: 2.92969vh; }
  .mb25 {
    margin-bottom: 2.44141vh; }
  .mb20 {
    margin-bottom: 1.95312vh; }
  .mb15 {
    margin-bottom: 1.46484vh; }
  .mt50 {
    margin-top: 4.88281vh; }
  .mt30 {
    margin-top: 2.92969vh; }
  .logo {
    width: 16.27604vw; }
  .mr20 {
    margin-right: 2.60417vw; }
  .mr10 {
    margin-right: 1.30208vw; }
  .mt20 {
    margin-top: 1.95312vh; }
  .mb100-tablet {
    margin-bottom: 100px; }
  .input-default {
    padding: 12px 25px 12px 30px;
    width: 253px;
    height: 52px;
    line-height: 52px; }
  input::placeholder {
    font-size: 15px;
    line-height: 52px; }
  .social-list a {
    width: 6.51042vw; }
  .main-padding {
    padding-left: 15.625vw;
    padding-right: 15.625vw; } }

@media screen and (max-width: 600px) {
  .pt350 {
    padding-top: 350px; }
  .pt250 {
    padding-top: 250px; }
  .pt150 {
    padding-top: 150px; }
  .pt100 {
    padding-top: 100px; }
  .pt95 {
    padding-top: 95px; }
  .pb100 {
    padding-bottom: 100px; }
  .pb170 {
    padding-bottom: 170px; }
  .pb175 {
    padding-bottom: 175px; }
  .pt70 {
    padding-top: 70px; }
  .pt50 {
    padding-top: 50px; }
  .pt30 {
    padding-top: 30px; }
  .pt15 {
    padding-top: 15px; }
  .pt10 {
    padding-top: 10px; }
  .pb45 {
    padding-bottom: 45px; }
  .pb250 {
    padding-bottom: 250px; }
  .pb150 {
    padding-bottom: 150px; }
  .pl30 {
    padding-left: 30px; }
  .pl20 {
    padding-left: 20px; }
  .pl12 {
    padding-left: 12px; }
  .btn-padding {
    padding: 1.77778vh 1.73611vw; }
  .icon-cross {
    width: 28px;
    height: 28px; }
    .icon-cross::before, .icon-cross::after {
      margin-bottom: -14px; }
  .check {
    padding-left: 20px; }
    .check::after {
      width: 16px; }
  .active-link::after {
    width: 16px;
    right: -15px; }
  .mt150 {
    margin-top: 150px; }
  .mt120 {
    margin-top: 120px; }
  .mb165 {
    margin-bottom: 165px; }
  .mt70 {
    margin-top: 70px; }
  .mb70 {
    margin-bottom: 70px; }
  .mb50 {
    margin-bottom: 50px; }
  .mb40 {
    margin-bottom: 40px; }
  .mb30 {
    margin-bottom: 30px; }
  .mb25 {
    margin-bottom: 25px; }
  .mb20 {
    margin-bottom: 20px; }
  .mb15 {
    margin-bottom: 15px; }
  .mt50 {
    margin-top: 50px; }
  .mt30 {
    margin-top: 30px; }
  .logo {
    width: 125px; }
  .mr20 {
    margin-right: 20px; }
  .mr10 {
    margin-right: 10px; }
  .mt20 {
    margin-top: 20px; }
  .input-default {
    padding: 12px 50px; }
  .ios-input-padding {
    padding-top: 5px;
    padding-bottom: 5px; }
  .social-list a {
    width: 50px; }
  .main-padding {
    padding-left: 20px;
    padding-right: 20px; } }

.hidden {
  visibility: hidden; }

body header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 1;
  transition: transform .3s, opacity 1s; }
  @media screen and (max-width: 768px) {
    body header {
      align-items: center;
      padding-bottom: 9.76562vh; } }
  body header nav ul li {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative; }
    body header nav ul li:not(:last-child) {
      margin-bottom: 10px; }
      body header nav ul li:not(:last-child):hover a {
        color: #F7875F; }
    body header nav ul li a {
      color: #ffffff;
      margin-right: 0.34722vw; }
    body header nav ul li img {
      transition: filter .3s;
      width: 1.04167vw; }
    body header nav ul li ul.language {
      flex-grow: 0;
      position: absolute;
      top: 0; }
      body header nav ul li ul.language:hover li {
        display: flex; }
      body header nav ul li ul.language li {
        display: none;
        justify-content: flex-end;
        align-items: center; }
        body header nav ul li ul.language li:hover a {
          color: #F7875F; }
        body header nav ul li ul.language li a {
          color: #C5C5C5; }
        body header nav ul li ul.language li:first-child {
          display: flex; }
          body header nav ul li ul.language li:first-child img {
            opacity: 1; }
        body header nav ul li ul.language li img {
          opacity: 0;
          width: 0.69444vw; }
  @media screen and (min-width: 768px) {
    body header #burger .burger__wrapper-bottom {
      display: none; } }
  @media screen and (max-width: 768px) {
    body header #burger {
      position: relative;
      width: 37px;
      height: 30px;
      cursor: pointer;
      border-top: 3px solid #ffffff;
      border-bottom: 3px solid #ffffff; }
      body header #burger::after {
        position: absolute;
        content: ' ';
        top: 50%;
        margin-top: -1.5px;
        height: 3px;
        width: 100%;
        background-color: #ffffff; }
      body header #burger .burger__wrapper {
        position: fixed;
        height: 100%;
        width: 100%;
        background: #00213C;
        top: 0;
        right: 0;
        bottom: 0;
        max-width: 75%;
        transform: translate(100%);
        transition: transform .3s;
        z-index: 9999;
        padding: 40px 20px 100px 0px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 100vh; }
        body header #burger .burger__wrapper-top #cross {
          width: 30px;
          height: 30px;
          position: relative;
          margin-left: auto; }
          body header #burger .burger__wrapper-top #cross::after, body header #burger .burger__wrapper-top #cross::before {
            position: absolute;
            content: ' ';
            height: 3px;
            width: 100%;
            background-color: #ffffff;
            transform: rotate(45deg);
            top: 50%; }
          body header #burger .burger__wrapper-top #cross::before {
            transform: rotate(-45deg); }
        body header #burger .burger__wrapper-top ul {
          margin-top: 60px; }
          body header #burger .burger__wrapper-top ul li a {
            color: #ffffff; } }
        @media screen and (max-width: 768px) and (max-width: 500px) {
          body header #burger .burger__wrapper-top ul li a {
            font-size: 18px; } }
  @media screen and (max-width: 768px) {
          body header #burger .burger__wrapper-top ul li img {
            width: 15px;
            height: 15px;
            margin-left: 10px; }
          body header #burger .burger__wrapper-top ul li .language {
            margin-top: initial; }
            body header #burger .burger__wrapper-top ul li .language li img {
              width: 10px; }
        body header #burger .burger__wrapper-bottom .social-list {
          justify-content: flex-end; }
        body header #burger .burger__wrapper-bottom ul {
          margin-top: 50px; }
          body header #burger .burger__wrapper-bottom ul li a {
            font-size: 18px; } }

body .header__scroll {
  background-color: #ffffff;
  position: fixed;
  left: 0;
  top: 0;
  align-items: center;
  padding: 1.11111vh 8.33333vw;
  max-height: 100px;
  transition: transform .3s, opacity 1s, box-shadow .3s;
  z-index: 9; }
  body .header__scroll:hover {
    box-shadow: 1.11111vh 1.11111vh 11.11111vh rgba(0, 0, 0, 0.05);
    transform: translate(0px, 0px) !important; }
  @media screen and (max-width: 500px) {
    body .header__scroll {
      padding: 10px 20px; } }
  body .header__scroll .logo {
    height: 100%;
    display: flex;
    align-items: center; }
    body .header__scroll .logo svg {
      --color-logo: #00213C; }
  @media screen and (max-width: 768px) {
    body .header__scroll #burger {
      border-top: 3px solid #00213C;
      border-bottom: 3px solid #00213C; }
      body .header__scroll #burger::after {
        background-color: #00213C; } }
  @media screen and (min-width: 768px) {
    body .header__scroll #burger .burger__wrapper .burger__wrapper-top ul {
      display: flex; }
      body .header__scroll #burger .burger__wrapper .burger__wrapper-top ul li {
        margin-left: 50px;
        margin-bottom: initial; }
        body .header__scroll #burger .burger__wrapper .burger__wrapper-top ul li:last-child {
          display: none; }
        body .header__scroll #burger .burger__wrapper .burger__wrapper-top ul li a {
          color: #00213C; }
          body .header__scroll #burger .burger__wrapper .burger__wrapper-top ul li a:hover {
            color: #F7875F; } }

body .first-section__wrapper {
  background-image: url(../img/first_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: columns;
  justify-content: space-between; }
  @media screen and (max-width: 768px) {
    body .first-section__wrapper {
      height: auto; } }
  @media screen and (max-width: 768px) {
    body .first-section__wrapper .first-section__content {
      padding-bottom: 6.83594vh; } }
  @media screen and (max-width: 500px) {
    body .first-section__wrapper .first-section__content {
      padding-bottom: 70px; } }
  body .first-section__wrapper .first-section__content .text-block {
    width: 60%; }
    @media screen and (max-width: 768px) {
      body .first-section__wrapper .first-section__content .text-block {
        width: 100%; } }
    body .first-section__wrapper .first-section__content .text-block p {
      width: 60%; }
    body .first-section__wrapper .first-section__content .text-block a {
      display: inline-flex;
      align-items: center; }
      body .first-section__wrapper .first-section__content .text-block a img {
        width: 1.04167vw;
        height: 0.97222vw;
        margin-left: 5px; }
        @media screen and (max-width: 768px) {
          body .first-section__wrapper .first-section__content .text-block a img {
            width: 1.95312vw;
            height: 1.82292vw; } }
        @media screen and (max-width: 500px) {
          body .first-section__wrapper .first-section__content .text-block a img {
            width: 15px;
            height: 15px; } }
  body .first-section__wrapper .first-section__bottom {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between; }
    @media screen and (max-width: 768px) {
      body .first-section__wrapper .first-section__bottom {
        align-items: center;
        justify-content: center; } }
    @media screen and (max-width: 768px) {
      body .first-section__wrapper .first-section__bottom .social-list {
        display: none; } }
    body .first-section__wrapper .first-section__bottom .scroll-down {
      width: 0.48611vw;
      height: 5.55556vh;
      animation: scrollDownShake 3s ease-in-out 2s infinite; }
      body .first-section__wrapper .first-section__bottom .scroll-down:hover {
        animation-play-state: paused; }
      @media screen and (max-width: 768px) {
        body .first-section__wrapper .first-section__bottom .scroll-down {
          width: 0.91146vw;
          height: 4.88281vh; } }
      @media screen and (max-width: 500px) {
        body .first-section__wrapper .first-section__bottom .scroll-down {
          width: 7px;
          height: 50px; } }
    @media screen and (max-width: 768px) {
      body .first-section__wrapper .first-section__bottom ul {
        display: none; } }
    body .first-section__wrapper .first-section__bottom ul li {
      display: flex;
      white-space: nowrap;
      justify-content: flex-start;
      align-items: center; }
      body .first-section__wrapper .first-section__bottom ul li:not(:first-child) {
        margin-top: 0.55556vh; }
      body .first-section__wrapper .first-section__bottom ul li:hover a {
        color: #F7875F; }
      body .first-section__wrapper .first-section__bottom ul li a {
        color: #ffffff; }

@keyframes scrollDownShake {
  0% {
    transform: translate(0, 0); }
  25% {
    transform: translate(0, 0); }
  50% {
    transform: translate(0, 10px); }
  75% {
    transform: translate(0, 0); }
  100% {
    transform: translate(0, 0); } }

body .our-goals__content {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }
  @media screen and (max-width: 768px) {
    body .our-goals__content {
      grid-template-columns: repeat(2, 1fr); } }
  @media screen and (max-width: 768px) {
    body .our-goals__content .our-goals__item {
      padding-bottom: 2.92969vh; } }
  @media screen and (max-width: 500px) {
    body .our-goals__content .our-goals__item {
      padding-bottom: initial;
      padding-top: 30px; } }
  body .our-goals__content .our-goals__item .our-goals__item-inner {
    padding-left: 1.38889vw;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start; }
    body .our-goals__content .our-goals__item .our-goals__item-inner .img-box {
      min-height: 80px; }
      @media screen and (max-width: 500px) {
        body .our-goals__content .our-goals__item .our-goals__item-inner .img-box {
          min-height: initial;
          height: 30px; }
          body .our-goals__content .our-goals__item .our-goals__item-inner .img-box img {
            height: 100%; } }
    body .our-goals__content .our-goals__item .our-goals__item-inner p {
      width: 100%; }
      @media screen and (max-width: 500px) {
        body .our-goals__content .our-goals__item .our-goals__item-inner p {
          font-size: 13px;
          margin-top: 13px; } }

body .clients__wrapper {
  transform: skewY(-5deg); }
  body .clients__wrapper .clients {
    position: relative;
    transform: skewY(5deg); }
  body .clients__wrapper .clients__logos {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    body .clients__wrapper .clients__logos span {
      margin: 10px 0; }
      @media screen and (max-width: 500px) {
        body .clients__wrapper .clients__logos span {
          flex-basis: 50%; } }
      body .clients__wrapper .clients__logos span img {
        object-fit: cover;
        height: 4.44444vh; }

body .absolute-lines {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 8.33333vw;
  right: 8.33333vw;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  z-index: 1; }
  @media all and (max-width: 768px) {
    body .absolute-lines {
      display: none; } }
  body .absolute-lines--d {
    z-index: -1; }

body .our-works__wrapper {
  position: relative;
  overflow-x: hidden; }

body .our-works__slider {
  background: transparent;
  overflow: hidden; }
  @media all and (max-width: 500px) {
    body .our-works__slider {
      overflow: visible;
      margin: 0 -20px; } }
  body .our-works__slider-item {
    margin: 0 -6px;
    width: 430px;
    height: 300px;
    overflow: hidden;
    position: relative; }
    body .our-works__slider-item:hover > div {
      display: block; }
    @media all and (max-width: 500px) {
      body .our-works__slider-item {
        width: 315px;
        height: 222px; } }
  body .our-works__slider-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transform: scale(0.837);
    transition: all .8s;
    cursor: pointer; }
  body .our-works__slider-scale {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 55px;
    bottom: 40px;
    background-image: url("../img/arrows_out-white.svg");
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
    display: none;
    transition: all .8s; }
  @media all and (max-width: 500px) {
    body .our-works__slider-buttons {
      display: flex;
      align-items: center;
      justify-content: center; } }

body .our-works__slider-wrap {
  position: relative; }
  body .our-works__slider-wrap .rounded-button {
    position: absolute;
    bottom: 0;
    top: 0;
    margin: auto; }
    @media all and (max-width: 500px) {
      body .our-works__slider-wrap .rounded-button {
        position: relative;
        margin: 15px 15px 0; } }
    body .our-works__slider-wrap .rounded-button--left {
      left: -25px; }
      @media all and (max-width: 500px) {
        body .our-works__slider-wrap .rounded-button--left {
          left: unset; } }
    body .our-works__slider-wrap .rounded-button--right {
      right: -25px; }
      @media all and (max-width: 500px) {
        body .our-works__slider-wrap .rounded-button--right {
          right: unset; } }
  body .our-works__slider-wrap .slick-list {
    margin: 0 -10px; }
  body .our-works__slider-wrap .slick-center {
    margin-top: 0; }
    body .our-works__slider-wrap .slick-center .our-works__slider-img {
      transform: scale(1);
      transition: all .8s; }
    body .our-works__slider-wrap .slick-center .our-works__slider-scale {
      right: 25px;
      bottom: 15px;
      transition: all .8s; }

body .you-will-get__wrapper {
  position: relative;
  background: #E9EFF6; }

body .you-will-get__content {
  z-index: 2;
  position: relative; }

body .you-will-get__items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-gap: 1px; }
  @media all and (max-width: 768px) {
    body .you-will-get__items {
      grid-template-columns: repeat(2, 1fr); } }
  @media all and (max-width: 500px) {
    body .you-will-get__items {
      grid-template-columns: 1fr; } }

body .you-will-get__item {
  padding: 0 25px;
  color: #00213C; }
  @media all and (max-width: 500px) {
    body .you-will-get__item {
      padding: 0; }
      body .you-will-get__item h4 {
        margin-left: 25px; } }
  @media all and (max-width: 768px) {
    body .you-will-get__item {
      margin-bottom: 50px; }
      body .you-will-get__item:last-child {
        margin-bottom: 0; } }

body .you-would-like__wrapper {
  position: relative;
  padding-bottom: 150px; }

body .you-would-like__items {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  margin: 0 -5px; }
  @media all and (max-width: 395px) {
    body .you-would-like__items {
      justify-content: space-around; } }

body .you-would-like__item {
  color: #ffffff;
  background: #ccc;
  position: relative;
  min-width: 168px;
  flex: 0 0 15%;
  margin: 0 5px 10px; }
  @media all and (max-width: 415px) {
    body .you-would-like__item {
      min-width: 160px; } }
  body .you-would-like__item .height-equal-width {
    padding-top: 100%; }
  body .you-would-like__item .content {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-size: 100%;
    background-repeat: no-repeat;
    padding-right: 25px; }
    body .you-would-like__item .content::before {
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      position: absolute;
      background: linear-gradient(180deg, rgba(0, 33, 60, 0.5) 0%, rgba(0, 33, 60, 0) 100%); }
    body .you-would-like__item .content:hover {
      transition: background-size .6s;
      z-index: 1;
      background-size: 130%;
      background-position: 30%; }
      body .you-would-like__item .content:hover .you-would-like__heading {
        color: #F7875F;
        transition: color .6s; }

body .you-would-like__heading {
  position: relative; }
  body .you-would-like__heading:after {
    content: '';
    width: 15px;
    height: 15px;
    margin-left: 5px;
    display: inline-block;
    background-image: url("../img/orArrow.svg");
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0.55556vh; }
    @media all and (max-width: 1300px) {
      body .you-would-like__heading:after {
        bottom: 0.44444vh; } }
    @media all and (max-width: 1200px) {
      body .you-would-like__heading:after {
        bottom: 0.33333vh; } }
    @media all and (max-width: 1000px) {
      body .you-would-like__heading:after {
        bottom: 0.22222vh; } }

body .services__wrapper {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: auto; }

body .services-first-section__wrapper {
  background-image: url(../img/aboutUs__bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: left; }

body .all-services {
  position: relative; }
  body .all-services__content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    grid-gap: 10px 0; }
    @media all and (max-width: 910px) {
      body .all-services__content {
        grid-template-columns: repeat(3, 1fr); } }
    @media all and (max-width: 680px) {
      body .all-services__content {
        grid-template-columns: repeat(2, 1fr); } }
    @media all and (max-width: 450px) {
      body .all-services__content {
        grid-template-columns: 1fr; } }
  body .all-services__item {
    min-width: 182px;
    position: relative;
    margin-right: 10px; }
    body .all-services__item .height-equal-width {
      padding-top: 100%; }
    body .all-services__item .content {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-size: 100%;
      background-repeat: no-repeat;
      padding-right: 20px;
      transition: background-size .6s, background-position 2s; }
      body .all-services__item .content::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: block;
        background: linear-gradient(180deg, rgba(0, 33, 60, 0.5) 0%, rgba(0, 33, 60, 0) 100%); }
      body .all-services__item .content:hover {
        transition: background-size .6s;
        z-index: 1;
        background-size: 130%;
        background-position: 30%; }
        body .all-services__item .content:hover .all-services__heading {
          color: #F7875F;
          transition: color .6s; }
  body .all-services__heading {
    position: relative;
    word-break: break-word; }
    body .all-services__heading:after {
      content: '';
      width: 20px;
      height: 20px;
      margin-left: 5px;
      display: inline-block;
      background-image: url("../img/orArrow.svg");
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      bottom: 0.88889vh; }
      @media all and (max-width: 1200px) {
        body .all-services__heading:after {
          bottom: 0.44444vh; } }
      @media all and (max-width: 1000px) {
        body .all-services__heading:after {
          bottom: 0.22222vh; } }

body .materials__wrapper {
  background: #E9EFF6; }

body .materials__items {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between; }
  @media all and (max-width: 1255px) {
    body .materials__items {
      justify-content: space-around; } }

body .materials__item {
  flex: 0 0 32%;
  min-width: 345px;
  margin-right: 5px; }
  body .materials__item:last-child {
    margin-right: 0; }
  @media all and (max-width: 1255px) {
    body .materials__item {
      margin-bottom: 30px;
      flex: 0 0 40%; }
      body .materials__item:last-child {
        margin-bottom: 0; } }
  @media all and (max-width: 840px) {
    body .materials__item {
      flex: 0 0 70%; } }
  @media all and (max-width: 500px) {
    body .materials__item {
      flex: 0 0 100%; } }

body .materials__img {
  width: 100%;
  height: 215px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative; }
  body .materials__img::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(0, 33, 60, 0.5) 0%, rgba(255, 255, 255, 0) 100%); }
  body .materials__img h3 {
    position: relative;
    z-index: 1; }

body .materials__text-block {
  width: calc(100% - 30px);
  height: auto;
  min-height: 193px;
  background: #fff;
  margin-top: -30px;
  padding-bottom: 10px;
  position: relative; }
  body .materials__text-block li {
    color: #00213C;
    position: relative;
    margin-bottom: 15px;
    padding-left: 17px;
    font-size: 15px; }
    body .materials__text-block li:before {
      content: '•';
      font-size: 32px;
      display: inline-block;
      width: 7px;
      height: 7px;
      margin-right: 10px;
      color: #F7875F;
      line-height: 1px;
      position: absolute;
      top: 1vh;
      left: 0; }
      @media all and (max-width: 1200px) {
        body .materials__text-block li:before {
          top: 0.77778vh; } }

body .callback__wrapper {
  background-image: url("../img/callback__bg.png");
  background-size: cover;
  background-repeat: no-repeat; }

body .callback__heading {
  color: #ffffff;
  border-left-color: #ffffff; }

body .callback__text {
  color: #ffffff;
  max-width: 525px; }
  body .callback__text a {
    color: #F7875F;
    text-decoration: underline; }

body .callback__form {
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  @media all and (max-width: 768px) {
    body .callback__form {
      flex-direction: column;
      align-items: center; } }
  body .callback__form label {
    margin: 0 0.86806vw;
    position: relative; }
    @media all and (max-width: 768px) {
      body .callback__form label {
        margin: 3.33333vh 0.86806vw 0; }
        body .callback__form label .subm {
          width: 253px; } }
    body .callback__form label img {
      position: absolute;
      left: 1.73611vw;
      width: 1.04167vw;
      height: 0.97222vw;
      top: 50%;
      margin-top: -0.48611vw; }
      @media all and (max-width: 768px) {
        body .callback__form label img {
          width: 15px;
          height: 15px;
          top: 0;
          bottom: 0;
          margin: auto;
          left: 3.25521vw; } }
      @media screen and (max-width: 500px) {
        body .callback__form label img {
          left: 25px;
          width: 15px;
          height: 14px; } }
  body .callback__form .img-reverted img {
    left: unset;
    right: 1.73611vw; }
  body .callback__form .svg-arrow {
    width: 10px;
    height: 10px; }
  @media all and (min-width: 769px) {
    body .callback__form input {
      height: 100%; } }

body .slider-window {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.95);
  z-index: 10;
  display: flex; }
  body .slider-window__img {
    object-fit: contain;
    width: calc(100% - 120px);
    height: auto;
    max-width: calc(100% - 120px);
    max-height: 85%;
    margin: auto;
    position: relative;
    z-index: 4; }
    @media all and (max-width: 550px) {
      body .slider-window__img {
        max-width: 99%;
        width: 99%; } }
  @media all and (max-width: 550px) {
    body .slider-window__buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 20px;
      right: 0;
      left: 0;
      margin: auto; } }
  body .slider-window .rounded-button {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 4; }
    @media all and (max-width: 550px) {
      body .slider-window .rounded-button {
        margin: 15px 15px 0;
        position: relative; } }
    body .slider-window .rounded-button--left {
      left: 0; }
    body .slider-window .rounded-button--right {
      right: 0; }
  body .slider-window__cross {
    width: 50px;
    height: 50px;
    background-color: #F7875F;
    border-radius: 50%;
    background-image: url("../img/cross.svg");
    background-size: 20px 20px;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 5; }

body .kitchen-first-section__wrapper {
  background-image: url(../img/kitchen__main-bg.png); }

body .livingRoom-first-section__wrapper {
  background-image: url(../img/livingRoom__main-bg.png); }

body .bedroom-first-section__wrapper {
  background-image: url(../img/bedroom__main-bg.png); }

body .wardrobe-first-section__wrapper {
  background-image: url(../img/wardrobe__main-bg.png); }

body .bath-first-section__wrapper {
  background-image: url(../img/bath__main-bg.png); }

body .childrenRoom-first-section__wrapper {
  background-image: url(../img/childrenRoom__main-bg.png); }

body .table-first-section__wrapper {
  background-image: url(../img/table__main-bg.png); }

body .hallway-first-section__wrapper {
  background-image: url(../img/hallway__main-bg.png); }

body .door-first-section__wrapper {
  background-image: url(../img/door__main-bg.png); }

body .stairs-first-section__wrapper {
  background-image: url(../img/stairs__main-bg.png); }

body .panels-first-section__wrapper {
  background-image: url(../img/panels__main-bg.png); }

body .business-first-section__wrapper {
  background-image: url(../img/business__main-bg.png); }

body .office__wrapper {
  position: relative; }

body .sale-staff__wrapper {
  position: relative; }

body .business-slider__content {
  display: grid;
  grid-template-columns: 5fr 17fr;
  grid-template-rows: 1fr; }
  @media all and (max-width: 768px) {
    body .business-slider__content {
      grid-template-columns: 1fr; } }

body .business-slider__text {
  min-width: 200px;
  flex: 0 0 7%;
  color: #00213C; }
  body .business-slider__text-item {
    margin-bottom: 10px;
    position: relative; }
    body .business-slider__text-item:last-child {
      margin-bottom: 0; }
    body .business-slider__text-item:before {
      content: '•';
      font-size: 32px;
      display: inline-block;
      width: 7px;
      height: 7px;
      color: #F7875F;
      line-height: 1px;
      position: absolute;
      top: 1vh;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 4px; }
      @media all and (max-width: 1000px) {
        body .business-slider__text-item:before {
          left: 1px; } }
      @media all and (max-width: 768px) {
        body .business-slider__text-item:before {
          left: 0; } }
    @media all and (max-width: 768px) {
      body .business-slider__text-item {
        padding-left: 20px; } }

body .business-slider__wrap {
  position: relative;
  overflow: hidden;
  padding-left: 2px; }
  body .business-slider__wrap .slick-center .business-slider__slider-scale {
    right: 25px;
    bottom: 15px;
    transition: all .8s; }
  @media all and (max-width: 768px) {
    body .business-slider__wrap {
      padding-left: 0;
      margin-top: 50px;
      margin-left: -8.33333vw; }
      body .business-slider__wrap .slick-center .business-slider__slider-img {
        transform: scale(1); } }

body .business-slider__slider-item {
  width: 332.5px;
  height: 250px;
  overflow: hidden;
  position: relative;
  margin: 0 5px; }
  body .business-slider__slider-item:hover > .business-slider__slider-scale {
    display: block; }
  @media all and (max-width: 768px) {
    body .business-slider__slider-item {
      width: 315px;
      height: 222px;
      margin: 0 -6px;
      transition: all .8s; } }

body .business-slider__slider-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  cursor: pointer; }
  @media all and (max-width: 768px) {
    body .business-slider__slider-img {
      transform: scale(0.837);
      transition: all .8s; } }

body .business-slider__slider-scale {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 25px;
  bottom: 15px;
  background-image: url("../img/arrows_out-white.svg");
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: all .8s;
  display: none; }
  @media all and (max-width: 768px) {
    body .business-slider__slider-scale {
      right: 40px;
      bottom: 30px; } }

body .business-slider__buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px; }
  body .business-slider__buttons .rounded-button {
    margin: 0 15px; }

body .facades-first-section__wrapper {
  background-image: url(../img/facades__main-bg.png); }

body .facades-kinds__wrapper {
  position: relative; }

body .facades-kinds__items {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  justify-content: space-between; }
  @media all and (max-width: 768px) {
    body .facades-kinds__items {
      flex-direction: column; } }

body .facades-kinds__item {
  flex: 0 0 32.8%;
  position: relative; }
  @media all and (max-width: 768px) {
    body .facades-kinds__item {
      margin-bottom: 50px; }
      body .facades-kinds__item:last-child {
        margin-bottom: 0; } }
  body .facades-kinds__item-img {
    object-fit: cover;
    width: 100%;
    margin-bottom: 15px; }
  body .facades-kinds__item-text {
    color: #00213C; }

body .painting-first-section__wrapper {
  background-image: url(../img/painting__main-bg.png); }

body .provide-painting__wrapper {
  position: relative; }

body .provide-painting__items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-gap: 50px 10px; }
  @media all and (max-width: 768px) {
    body .provide-painting__items {
      grid-template-columns: repeat(2, 1fr); } }
  @media all and (max-width: 500px) {
    body .provide-painting__items {
      grid-template-columns: 1fr; } }

body .provide-painting__img-wrap {
  position: relative; }
  body .provide-painting__img-wrap:after {
    content: '';
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(0, 33, 60, 0.5) 0%, rgba(0, 33, 60, 0) 100%);
    position: absolute;
    z-index: 1; }

body .provide-painting__img {
  width: 100%;
  object-fit: cover;
  margin-bottom: 15px;
  background: linear-gradient(180deg, rgba(0, 33, 60, 0.5) 0%, rgba(0, 33, 60, 0) 100%);
  position: relative; }

body .provide-painting__text {
  color: #00213C; }

body .painting-materials__wrapper {
  background-color: #E9EFF6;
  position: relative;
  padding-bottom: 240px; }
  @media all and (max-width: 920px) {
    body .painting-materials__wrapper {
      padding-bottom: unset; } }

body .painting-materials__content {
  position: relative;
  z-index: 2; }

body .painting-materials__heading {
  display: block; }

body .painting-materials__text {
  color: #00213C;
  max-width: 495px; }

body .painting-materials__img {
  position: absolute; }
  @media all and (max-width: 1275px) {
    body .painting-materials__img {
      transform: scale(0.8); } }
  @media all and (max-width: 1090px) {
    body .painting-materials__img {
      transform: scale(0.6); } }
  @media all and (max-width: 920px) {
    body .painting-materials__img {
      position: static; } }
  body .painting-materials__img--first {
    top: 0;
    left: 33%;
    width: 438px;
    height: 433px; }
    @media all and (max-width: 920px) {
      body .painting-materials__img--first {
        width: 262px;
        height: 260px;
        transform: scale(1); } }
    @media all and (max-width: 500px) {
      body .painting-materials__img--first {
        width: 210px;
        height: 208px; } }
  body .painting-materials__img--second {
    top: 0;
    right: 0;
    width: 369px;
    height: 333px; }
    @media all and (max-width: 1275px) {
      body .painting-materials__img--second {
        right: -40px; } }
    @media all and (max-width: 1090px) {
      body .painting-materials__img--second {
        right: -80px; } }
    @media all and (max-width: 920px) {
      body .painting-materials__img--second {
        width: 221px;
        height: 200px;
        transform: scale(1); } }
    @media all and (max-width: 500px) {
      body .painting-materials__img--second {
        width: 176px;
        height: 160px; } }

@media all and (max-width: 920px) {
  body .painting-materials__images {
    display: flex;
    justify-content: space-around;
    align-items: center; } }

@media all and (max-width: 385px) {
  body .painting-materials__images {
    flex-direction: column;
    padding-bottom: 100px;
    padding-top: 20px; } }

body .upholstery-first-section__wrapper {
  background-image: url(../img/upholstery__main-bg.png); }

body .aboutUs-first-section__wrapper {
  background-image: url(../img/aboutUs__bg.png);
  background-attachment: fixed;
  background-position: left;
  background-size: cover; }

body .aboutUs-first-section__content {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 1fr; }
  @media all and (max-width: 768px) {
    body .aboutUs-first-section__content {
      grid-template-columns: 1fr; }
      body .aboutUs-first-section__content h1 {
        text-align: center; } }

body .mission__wrapper {
  position: relative; }

body .mission__content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr; }
  @media all and (max-width: 768px) {
    body .mission__content {
      grid-template-columns: 1fr; } }

body .mission__textBlock {
  max-width: 495px; }
  @media all and (max-width: 768px) {
    body .mission__textBlock {
      padding-left: 0; } }
  body .mission__textBlock p {
    color: #00213C; }

body .concept__wrapper {
  position: relative;
  background: #E9EFF6; }

body .concept__content {
  position: relative;
  z-index: 2; }

body .concept__items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr; }
  @media all and (max-width: 768px) {
    body .concept__items {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 50px 10px; } }
  @media all and (max-width: 500px) {
    body .concept__items {
      grid-template-columns: 1fr;
      grid-gap: 50px 0; } }

body .concept__item {
  position: relative; }
  body .concept__item p {
    max-width: 250px; }
    @media all and (max-width: 500px) {
      body .concept__item p {
        max-width: unset;
        text-align: center; } }
  @media all and (max-width: 500px) {
    body .concept__item h4 {
      text-align: center; } }

body .concept__bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1; }
  @media all and (max-width: 500px) {
    body .concept__bg {
      right: 0;
      margin: auto; } }

body .produce-from__wrapper {
  background-image: url("../img/produce_from__bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  padding-bottom: 220px; }
  @media all and (max-width: 768px) {
    body .produce-from__wrapper {
      background-position: 45%; } }

body .produce-from__heading {
  color: #ffffff;
  border-left-color: #ffffff; }

body .produce-from__text {
  max-width: 700px; }

body .contacts-first-section__wrapper {
  background-image: url("../img/aboutUs__bg.png");
  background-attachment: fixed;
  background-position: left;
  background-size: cover; }

body .contact-us__wrapper {
  position: relative; }

body .contact-us__content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr; }
  @media all and (max-width: 768px) {
    body .contact-us__content {
      grid-template-columns: 1fr;
      grid-gap: 150px 0; } }

body .contact-us__li {
  position: relative;
  padding-left: 20px; }
  body .contact-us__li--email {
    padding-left: 30px; }

@media all and (max-width: 768px) {
  body .contact-us__links--phones {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px 0; } }

@media all and (max-width: 425px) {
  body .contact-us__links--phones {
    grid-template-columns: 1fr; } }

body .contact-us__link {
  color: #00213C;
  padding: 3px 12px;
  transition: none; }
  body .contact-us__link:hover {
    background: #00213C;
    color: #fff; }

body .contact-us__img {
  position: absolute;
  left: 5px;
  top: 0;
  bottom: 0;
  margin: auto; }

body .contact-us__h4 {
  color: #00213C; }

body .contact-us__socialBlock {
  display: flex;
  padding-top: 15px; }

body .contact-us__social-icon {
  width: 50px;
  height: 50px; }

body #success-modal,
body #error-modal {
  display: none;
  text-align: center;
  margin-top: 50px; }

body .find-us__wrapper {
  position: relative;
  background: #E9EFF6; }

body .find-us__content {
  position: relative;
  z-index: 2;
  display: grid;
  grid-template-columns: 5fr 7fr;
  grid-template-rows: 1fr; }
  @media all and (max-width: 768px) {
    body .find-us__content {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
      grid-gap: 50px 0; } }

body .find-us__h4 {
  color: #00213C;
  margin-bottom: 10px;
  position: relative; }
  body .find-us__h4--address {
    padding-left: 25px; }
    body .find-us__h4--address:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url("../img/location-dark.svg");
      display: block;
      width: 15px;
      height: 21px; }
  body .find-us__h4--time {
    padding-left: 31px; }
    body .find-us__h4--time:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url("../img/clock-dark.svg");
      display: block;
      width: 21px;
      height: 21px; }

body .find-us__text {
  color: #00213C;
  padding-left: 2px; }

body .main-services__content {
  display: none; }
  @media screen and (max-width: 768px) {
    body .main-services__content {
      display: grid;
      padding: initial; }
      body .main-services__content h2 {
        grid-column: 1/3;
        margin-bottom: 4.88281vh; } }

@media screen and (max-width: 768px) {
  body .our-products__wrapper {
    padding-top: 14.64844vh; } }

@media screen and (max-width: 500px) {
  body .our-products__wrapper {
    padding-top: 150px; } }

body .our-products__wrapper .our-products__content {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }
  @media screen and (max-width: 768px) {
    body .our-products__wrapper .our-products__content {
      display: none; } }
  body .our-products__wrapper .our-products__content h2 {
    grid-column: 1/5; }
  body .our-products__wrapper .our-products__content ul li:last-child a {
    color: #F7875F;
    background-color: initial;
    border: 1px solid #F7875F;
    position: relative;
    align-items: center;
    white-space: nowrap; }
    body .our-products__wrapper .our-products__content ul li:last-child a:hover {
      color: #ffffff;
      background-color: #F7875F; }
    body .our-products__wrapper .our-products__content ul li:last-child a img {
      width: 1.04167vw;
      height: 0.97222vw;
      margin-left: 5px; }
      @media screen and (max-width: 768px) {
        body .our-products__wrapper .our-products__content ul li:last-child a img {
          width: 1.95312vw;
          height: 1.82292vw; } }
      @media screen and (max-width: 500px) {
        body .our-products__wrapper .our-products__content ul li:last-child a img {
          width: 15px;
          height: 15px; } }
  body .our-products__wrapper .our-products__content ul li a {
    color: #00213C; }
    body .our-products__wrapper .our-products__content ul li a:hover {
      color: #F7875F; }
  body .our-products__wrapper .our-products__content .our-products__images {
    grid-column: 2/5;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%; }
    body .our-products__wrapper .our-products__content .our-products__images .our-products__image {
      display: grid;
      grid-template-columns: 1.5fr;
      grid-template-rows: 1fr 1fr; }
      body .our-products__wrapper .our-products__content .our-products__images .our-products__image .our-products__image-box {
        position: relative; }
        body .our-products__wrapper .our-products__content .our-products__images .our-products__image .our-products__image-box img {
          max-height: 33.33333vh;
          max-width: 20.83333vw; }
        body .our-products__wrapper .our-products__content .our-products__images .our-products__image .our-products__image-box .product__img-1 {
          position: absolute;
          top: 0;
          left: 50%;
          margin-left: -25%; }
          @media screen and (max-width: 1440px) {
            body .our-products__wrapper .our-products__content .our-products__images .our-products__image .our-products__image-box .product__img-1 {
              left: 0; } }
        body .our-products__wrapper .our-products__content .our-products__images .our-products__image .our-products__image-box .product__img-2 {
          position: absolute;
          top: 0;
          left: -50px; }
        body .our-products__wrapper .our-products__content .our-products__images .our-products__image .our-products__image-box .product__img-3 {
          position: absolute;
          top: 5%;
          right: 0; }
        body .our-products__wrapper .our-products__content .our-products__images .our-products__image .our-products__image-box .product__img-4 {
          position: absolute;
          bottom: 20%;
          right: 0; }

body .our-products__wrapper .all-services__content {
  grid-template-columns: repeat(2, 50%); }
  @media screen and (max-width: 500px) {
    body .our-products__wrapper .all-services__content a {
      min-width: initial; } }
  body .our-products__wrapper .all-services__content .subm {
    grid-column: 1/3;
    margin: 50px auto;
    white-space: nowrap;
    color: #F7875F;
    background-color: #ffffff;
    border: 1px solid #F7875F;
    display: inline-flex;
    align-items: center;
    justify-content: space-around; }
    body .our-products__wrapper .all-services__content .subm img {
      width: 15px;
      height: 15px; }

body .our-numbers__wrapper {
  background-image: url(../img/numbers_bg.png);
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative; }
  @media screen and (max-width: 768px) {
    body .our-numbers__wrapper {
      height: auto; } }
  @media screen and (max-width: 500px) {
    body .our-numbers__wrapper {
      background-size: auto; } }
  body .our-numbers__wrapper .grid-template .border-item {
    border-left: 0.5px solid #E9EFF6;
    border-right: 0.5px solid #ffffff; }
  body .our-numbers__wrapper .numbers {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr); }
    @media screen and (max-width: 768px) {
      body .our-numbers__wrapper .numbers {
        grid-template-columns: repeat(2, 1fr);
        padding-top: 19.53125vh;
        padding-bottom: 19.53125vh; } }
    @media screen and (max-width: 500px) {
      body .our-numbers__wrapper .numbers {
        grid-template-columns: repeat(2, 1fr);
        padding-top: 200px;
        padding-bottom: 200px; } }
    body .our-numbers__wrapper .numbers .numbers__item {
      border-color: #ffffff;
      color: #ffffff;
      display: flex;
      align-items: center;
      flex-wrap: wrap; }
      @media screen and (max-width: 768px) {
        body .our-numbers__wrapper .numbers .numbers__item {
          border: initial; } }
      body .our-numbers__wrapper .numbers .numbers__item .text-block {
        position: relative;
        width: 100%; }
        @media screen and (max-width: 768px) {
          body .our-numbers__wrapper .numbers .numbers__item .text-block {
            padding-top: 7.8125vh; } }
        @media screen and (max-width: 500px) {
          body .our-numbers__wrapper .numbers .numbers__item .text-block {
            height: 100%; } }
        body .our-numbers__wrapper .numbers .numbers__item .text-block h2 {
          position: absolute;
          white-space: nowrap;
          color: #ffffff;
          border-color: #ffffff;
          top: 0; }
          @media screen and (max-width: 768px) {
            body .our-numbers__wrapper .numbers .numbers__item .text-block h2 {
              border: initial;
              padding: initial;
              padding-bottom: 3.90625vh; } }
        @media screen and (max-width: 500px) {
          body .our-numbers__wrapper .numbers .numbers__item .text-block h4 span {
            font-size: 60px; } }
        body .our-numbers__wrapper .numbers .numbers__item .text-block p {
          max-width: 60%; }

body .choose-us__wrapper {
  background-color: #E9EFF6;
  position: relative;
  overflow: hidden;
  padding-bottom: 5.55556vh; }
  @media screen and (max-width: 768px) {
    body .choose-us__wrapper {
      padding-bottom: 4.88281vh; } }
  @media screen and (max-width: 500px) {
    body .choose-us__wrapper {
      padding-bottom: 50px; } }
  body .choose-us__wrapper .grid-template .border-item {
    border-color: #ffffff; }
    @media screen and (max-width: 768px) {
      body .choose-us__wrapper .grid-template .border-item {
        border-right: 0.5px solid #ffffff; } }
  body .choose-us__wrapper .white-bg {
    height: 100%;
    background-color: #ffffff;
    z-index: 0;
    width: calc( 100% + 200px);
    position: absolute;
    bottom: -90%;
    right: -50px;
    transform: rotate(-5deg);
    overflow: hidden; }
    body .choose-us__wrapper .white-bg .grid-template {
      width: calc(100% - 8.33333vw - 8.33333vw - 200px);
      margin-left: calc( 50px + 100px + 8.33333vw);
      transform: rotate(5deg) translate(0px, -50%); }
      @media screen and (max-width: 500px) {
        body .choose-us__wrapper .white-bg .grid-template {
          display: none; } }
      body .choose-us__wrapper .white-bg .grid-template .border-item {
        border-color: #E9EFF6; }
  body .choose-us__wrapper .choose-us {
    position: relative; }
    @media screen and (max-width: 500px) {
      body .choose-us__wrapper .choose-us {
        padding-top: initial; } }
    body .choose-us__wrapper .choose-us .top-section {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      body .choose-us__wrapper .choose-us .top-section a {
        margin-right: 1.04167vw; }
    body .choose-us__wrapper .choose-us .choose-us__content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start; }
      @media screen and (max-width: 768px) {
        body .choose-us__wrapper .choose-us .choose-us__content {
          flex-wrap: wrap; }
          body .choose-us__wrapper .choose-us .choose-us__content a {
            margin-top: 4.88281vh; } }
      @media screen and (max-width: 500px) {
        body .choose-us__wrapper .choose-us .choose-us__content a {
          margin-top: 50px; } }
      body .choose-us__wrapper .choose-us .choose-us__content .choose-us__item {
        width: 25%; }
        @media screen and (max-width: 768px) {
          body .choose-us__wrapper .choose-us .choose-us__content .choose-us__item {
            width: 100%; }
            body .choose-us__wrapper .choose-us .choose-us__content .choose-us__item:not(:first-child) {
              margin-top: 4.88281vh; } }
        @media screen and (max-width: 500px) {
          body .choose-us__wrapper .choose-us .choose-us__content .choose-us__item:not(:first-child) {
            margin-top: 50px; } }
        body .choose-us__wrapper .choose-us .choose-us__content .choose-us__item p {
          color: #00213C; }

@media screen and (max-width: 768px) {
  body .process__wrapper.main-padding {
    z-index: 999; } }

@media screen and (max-width: 768px) {
  body .process__wrapper.main-padding .grid-template {
    display: none; } }

body .process__wrapper.main-padding section.process {
  position: relative;
  border-right: 1px dashed #E9EFF6;
  border-left: 1px dashed #E9EFF6; }
  body .process__wrapper.main-padding section.process .process__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr); }
    @media screen and (max-width: 768px) {
      body .process__wrapper.main-padding section.process .process__content {
        grid-template-columns: repeat(1, 1fr); } }
    body .process__wrapper.main-padding section.process .process__content .process__item {
      padding: 3.33333vh 3.47222vw;
      position: relative; }
      body .process__wrapper.main-padding section.process .process__content .process__item p {
        width: 70%;
        z-index: 999; }
        @media screen and (max-width: 768px) {
          body .process__wrapper.main-padding section.process .process__content .process__item p {
            width: 100%; } }
        body .process__wrapper.main-padding section.process .process__content .process__item p a {
          color: #F7875F; }
      body .process__wrapper.main-padding section.process .process__content .process__item img {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1; }

body .portfolio__wrapper {
  width: 100%;
  height: 90vh;
  background-color: #00213C;
  position: relative;
  transform: skewY(-5deg);
  overflow: hidden; }
  @media screen and (max-width: 1440px) {
    body .portfolio__wrapper {
      height: 70vh; } }
  @media screen and (max-width: 1200px) {
    body .portfolio__wrapper {
      height: 60vh; } }
  @media screen and (max-width: 900px) {
    body .portfolio__wrapper {
      height: 50vh; } }
  @media screen and (max-width: 768px) {
    body .portfolio__wrapper {
      height: auto; } }
  @media screen and (max-width: 500px) {
    body .portfolio__wrapper .grid-template .border-left-d {
      border-left: initial; } }
  body .portfolio__wrapper .portfolio {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr); }
    @media screen and (max-width: 768px) {
      body .portfolio__wrapper .portfolio {
        grid-template-columns: repeat(2, 1fr); } }
    @media screen and (max-width: 500px) {
      body .portfolio__wrapper .portfolio .portfolio__item.pt150 {
        padding-top: 100px; } }
    body .portfolio__wrapper .portfolio .portfolio__item {
      z-index: 10000;
      border-color: #14344F;
      color: #ffffff;
      display: flex;
      flex-wrap: wrap; }
      @media screen and (max-width: 768px) {
        body .portfolio__wrapper .portfolio .portfolio__item {
          grid-column: 1/3; } }
      body .portfolio__wrapper .portfolio .portfolio__item .text-block {
        position: relative;
        width: 100%;
        transform: skewY(5deg); }
        body .portfolio__wrapper .portfolio .portfolio__item .text-block a {
          display: block; }
          body .portfolio__wrapper .portfolio .portfolio__item .text-block a::after {
            right: initial; }
          @media screen and (max-width: 500px) {
            body .portfolio__wrapper .portfolio .portfolio__item .text-block a {
              margin-top: 50px; } }
        body .portfolio__wrapper .portfolio .portfolio__item .text-block h2 {
          position: absolute;
          white-space: nowrap;
          color: #ffffff;
          border-color: #ffffff;
          top: 0; }
        body .portfolio__wrapper .portfolio .portfolio__item .text-block p {
          max-width: 60%; }
    body .portfolio__wrapper .portfolio .portfolio__galery {
      right: 8.33333vw;
      top: 0;
      z-index: 1;
      height: 100%;
      transform: skewY(5deg);
      position: absolute;
      z-index: 999999999999999; }
      @media screen and (max-width: 768px) {
        body .portfolio__wrapper .portfolio .portfolio__galery {
          grid-column: 1/3;
          position: static;
          width: 130%;
          margin-left: -15%;
          margin-top: 4.88281vh; } }
      body .portfolio__wrapper .portfolio .portfolio__galery .portfolio__galery-body {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, 1fr);
        grid-row-gap: 3.125vw;
        transition: transform .3s; }
        body .portfolio__wrapper .portfolio .portfolio__galery .portfolio__galery-body .portfolio__galery-body-column {
          display: flex; }
          @media screen and (max-width: 768px) {
            body .portfolio__wrapper .portfolio .portfolio__galery .portfolio__galery-body .portfolio__galery-body-column {
              justify-content: space-between; } }
          body .portfolio__wrapper .portfolio .portfolio__galery .portfolio__galery-body .portfolio__galery-body-column:nth-child(2) {
            transform: translate(-3.47222vw); }
          body .portfolio__wrapper .portfolio .portfolio__galery .portfolio__galery-body .portfolio__galery-body-column img {
            width: 13.88889vw;
            height: 13.88889vw;
            object-fit: cover; }
            @media screen and (max-width: 768px) {
              body .portfolio__wrapper .portfolio .portfolio__galery .portfolio__galery-body .portfolio__galery-body-column img {
                width: 26.04167vw;
                height: 26.04167vw; } }
            body .portfolio__wrapper .portfolio .portfolio__galery .portfolio__galery-body .portfolio__galery-body-column img:not(:last-child) {
              margin-right: 3.47222vw; }

body #modal__wrapper {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.7);
  display: none;
  align-items: center;
  justify-content: center;
  padding: 0 15.625vw; }
  @media screen and (max-width: 768px) {
    body #modal__wrapper {
      padding: 0 12px; } }
  body #modal__wrapper .modal__content {
    position: relative;
    padding: 5.55556vh 6.94444vw 11.11111vh;
    border-radius: 10px;
    box-shadow: 1.11111vh 1.11111vh 11.11111vh rgba(0, 0, 0, 0.05);
    background-color: #00213C; }
    @media screen and (max-width: 768px) {
      body #modal__wrapper .modal__content {
        padding: 5.55556vh 12px 11.11111vh; } }
    @media screen and (max-width: 500px) {
      body #modal__wrapper .modal__content {
        padding-bottom: 5.55556vh; } }
    body #modal__wrapper .modal__content #success-modal,
    body #modal__wrapper .modal__content #error-modal {
      margin-top: 2.22222vh;
      text-align: center;
      display: none; }
    body #modal__wrapper .modal__content #modal__cross {
      position: absolute;
      left: initial !important;
      bottom: initial !important;
      top: 3.33333vh !important;
      right: 2.08333vw !important; }
      @media screen and (max-width: 768px) {
        body #modal__wrapper .modal__content #modal__cross {
          top: -15px !important;
          right: 12px !important; } }
      body #modal__wrapper .modal__content #modal__cross::before, body #modal__wrapper .modal__content #modal__cross::after {
        background-color: #ffffff; }
    body #modal__wrapper .modal__content p {
      width: 80%;
      margin-top: 2.22222vh;
      color: #ffffff; }
      @media screen and (max-width: 500px) {
        body #modal__wrapper .modal__content p {
          width: 100%; } }
      body #modal__wrapper .modal__content p a {
        color: #F7875F; }
    body #modal__wrapper .modal__content form {
      margin-top: 5.55556vh;
      width: 100%;
      top: 11.11111vh;
      height: auto;
      display: flex;
      justify-content: space-between; }
      @media screen and (max-width: 768px) {
        body #modal__wrapper .modal__content form {
          flex-wrap: wrap;
          justify-content: center; } }
      body #modal__wrapper .modal__content form label {
        position: relative; }
        @media screen and (max-width: 768px) {
          body #modal__wrapper .modal__content form label {
            flex-basis: 50%; } }
        @media screen and (max-width: 500px) {
          body #modal__wrapper .modal__content form label {
            margin-top: 15px; } }
        body #modal__wrapper .modal__content form label img {
          position: absolute;
          left: 1.73611vw;
          width: 1.04167vw;
          height: 0.97222vw;
          top: 50%;
          margin-top: -0.48611vw; }
          @media screen and (max-width: 768px) {
            body #modal__wrapper .modal__content form label img {
              width: 1.95312vw;
              height: 1.82292vw;
              margin-top: -0.91146vw; } }
          @media screen and (max-width: 500px) {
            body #modal__wrapper .modal__content form label img {
              width: 15px;
              height: 14px;
              left: 25px;
              margin-top: -7px; } }
        body #modal__wrapper .modal__content form label button {
          white-space: nowrap; }
          @media screen and (max-width: 768px) {
            body #modal__wrapper .modal__content form label button {
              margin-top: 25px;
              width: 253px; } }
          @media screen and (max-width: 500px) {
            body #modal__wrapper .modal__content form label button {
              padding: 0 15px; } }
      body #modal__wrapper .modal__content form .img-reverted img {
        left: initial;
        right: 1.73611vw; }
        @media screen and (max-width: 768px) {
          body #modal__wrapper .modal__content form .img-reverted img {
            display: none; } }
      body #modal__wrapper .modal__content form input {
        height: 100%; }
      body #modal__wrapper .modal__content form h3 {
        width: 100%;
        color: #ffffff; }

body footer > * {
  height: 100%; }

body footer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  width: 100%;
  background-color: #00213C;
  align-items: start;
  position: relative;
  color: #ffffff; }
  @media screen and (max-width: 768px) {
    body footer {
      grid-template-columns: repeat(1, 1fr);
      padding-bottom: 9.76562vh; } }
  @media screen and (max-width: 500px) {
    body footer {
      padding-bottom: 200px; } }
  body footer .form {
    width: 100%;
    position: absolute;
    top: 11.11111vh;
    height: auto;
    display: flex;
    flex-flow: column wrap;
    justify-content: center; }
    @media screen and (max-width: 768px) {
      body footer .form {
        padding: 9.76562vh 0px;
        position: static; } }
    @media screen and (max-width: 500px) {
      body footer .form {
        margin: 50px 0px; } }
    body footer .form form {
      margin-top: 25px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center; }
      body footer .form form #success,
      body footer .form form #error {
        text-align: center;
        display: none; }
      body footer .form form label {
        margin: 3.33333vh 0.86806vw 0;
        position: relative; }
        @media screen and (max-width: 768px) {
          body footer .form form label {
            margin: 2.92969vh 1.6276vw 0; } }
        @media screen and (max-width: 500px) {
          body footer .form form label {
            width: 75%; } }
        body footer .form form label img {
          position: absolute;
          left: 1.73611vw;
          width: 1.04167vw;
          height: 0.97222vw;
          top: 50%;
          margin-top: -0.48611vw; }
          @media screen and (max-width: 768px) {
            body footer .form form label img {
              left: 3.25521vw;
              width: 1.95312vw;
              height: 1.82292vw;
              margin-top: -0.91146vw; } }
          @media screen and (max-width: 500px) {
            body footer .form form label img {
              left: 25px;
              width: 15px;
              height: 14px;
              margin-top: -7px; } }
        body footer .form form label input {
          height: 100%; }
          @media screen and (max-width: 500px) {
            body footer .form form label input {
              width: 100% !important; } }
      body footer .form form .img-reverted img {
        left: initial;
        right: 1.73611vw; }
        @media screen and (max-width: 768px) {
          body footer .form form .img-reverted img {
            right: 3.25521vw; } }
        @media screen and (max-width: 500px) {
          body footer .form form .img-reverted img {
            right: 25px; } }
      body footer .form form input {
        height: 100%; }
        @media screen and (max-width: 500px) {
          body footer .form form input {
            width: 100%; } }
      @media screen and (max-width: 500px) {
        body footer .form form button {
          width: 100%; } }
      body footer .form form h3 {
        width: 100%;
        color: #ffffff; }
  body footer .main-body-small {
    color: #ffffff; }
  @media screen and (max-width: 768px) {
    body footer a,
    body footer div,
    body footer ul {
      padding: initial !important;
      margin: 0;
      border: initial !important; } }
  @media screen and (max-width: 768px) {
    body footer ul {
      margin-top: 50px; } }
  body footer ul li {
    color: #ffffff;
    align-items: flex-end;
    display: flex;
    text-align: center; }
    body footer ul li:first-child {
      margin-bottom: 0.55556vh; }
    body footer ul li:not(:first-child) {
      margin-top: 1.11111vh; }
    body footer ul li a {
      color: #ffffff; }
      body footer ul li a:hover {
        color: #F7875F; }
      @media screen and (max-width: 768px) {
        body footer ul li a {
          margin: 0; } }
  @media screen and (max-width: 768px) {
    body footer div ul.mt50 li a {
      margin: initial; } }

body .http-error__wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-image: url("../img/caution-tape.png");
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 100%; }

body .http-error__button {
  display: flex;
  justify-content: center; }

body .http-error__text {
  max-width: 475px;
  margin-left: auto;
  margin-right: auto; }

body .banner {
  width: 100%;
  height: 70px;
  background: #F7875F;
  overflow-x: hidden;
  white-space: nowrap; }
  body .banner__text {
    margin: 0;
    line-height: 70px;
    display: inline-block;
    padding-left: 100%;
    animation: marquee 12s linear infinite;
    font-size: 17px;
    font-weight: 300; }
    body .banner__text:before {
      content: '';
      display: inline-block;
      margin: 0 13px -5px 0;
      width: 25px;
      height: 26px;
      background: url("../img/fire.svg"); }

@keyframes marquee {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(-100%, 0); } }
