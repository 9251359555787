.you-would-like {
    &__wrapper {
        position: relative;
        padding-bottom: 150px; }
    &__items {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
        margin: 0 -5px;
        @media all and (max-width: 395px) {
            justify-content: space-around; } }
    &__item {
        color: $white;
        background: #ccc;
        position: relative;
        min-width: 168px;
        flex: 0 0 15%;
        margin: 0 5px 10px;
        @media all and (max-width: 415px) {
            min-width: 160px; }
        .height-equal-width {
            padding-top: 100%; }
        .content {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background-size: 100%;
            background-repeat: no-repeat;
            padding-right: 25px;
            &::before {
                content: '';
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                position: absolute;
                background: linear-gradient(180deg, rgba(0, 33, 60, 0.5) 0%, rgba(0, 33, 60, 0) 100%); }
            &:hover {
                transition: background-size .6s;
                z-index: 1;
                background-size: 130%;
                background-position: 30%;
                .you-would-like__heading {
                    color: $orange;
                    transition: color .6s; } } } }
    &__heading {
        position: relative;
        &:after {
            content: '';
            width: 15px;
            height: 15px;
            margin-left: 5px;
            display: inline-block;
            background-image: url('../img/orArrow.svg');
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;
            bottom: calculateHeight(5);
            @media all and (max-width: 1300px) {
                bottom: calculateHeight(4); }
            @media all and (max-width: 1200px) {
                bottom: calculateHeight(3); }
            @media all and (max-width: 1000px) {
                bottom: calculateHeight(2); } } } }
