.portfolio__wrapper {
    width: 100%;
    height: 90vh;
    background-color: $dark_blue;
    position: relative;
    transform: skewY(-5deg);
    overflow: hidden;
    @media screen and (max-width: 1440px) {
        height: 70vh; }
    @media screen and (max-width: 1200px) {
        height: 60vh; }
    @media screen and (max-width: 900px) {
        height: 50vh; }
    @media screen and (max-width: 768px) {
        height: auto; }
    .grid-template {
        .border-left-d {
            @media screen and ( max-width: 500px ) {
                border-left: initial; } } }
    .portfolio {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat( 4 , 1fr );
        @media screen and (max-width: 768px) {
            grid-template-columns: repeat( 2 , 1fr ); }
        .portfolio__item.pt150 {
            @media screen and ( max-width: 500px ) {
                padding-top: 100px; } }
        .portfolio__item {
            z-index: 10000;
            border-color: $border;
            color: $white;
            display: flex;
            flex-wrap: wrap;
            @media screen and (max-width: 768px) {
                grid-column: 1/3; }
            .text-block {
                position: relative;
                width: 100%;
                transform: skewY(5deg);
                a {
                    display: block;
                    &::after {
                        right: initial; }
                    @media screen and ( max-width: 500px ) {
                        margin-top: 50px; } }
                h2 {
                    position: absolute;
                    white-space: nowrap;
                    color: $white;
                    border-color: $white;
                    top: 0; }
                p {
                    max-width: 60%; } } }
        .portfolio__galery {
            right: calculateWidth(120);
            top: 0;
            z-index: 1;
            height: 100%;
            transform: skewY(5deg);
            position: absolute;
            z-index: 999999999999999;
            @media screen and (max-width: 768px) {
                grid-column: 1/3;
                position: static;
                width: 130%;
                margin-left: -15%;
                margin-top: tH(50); }
            .portfolio__galery-body {
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: repeat(3, 1fr);
                grid-row-gap: calculateWidth(45);
                transition: transform .3s;
                .portfolio__galery-body-column {
                    display: flex;
                    @media screen and (max-width: 768px) {
                        justify-content: space-between; }
                    &:nth-child(2) {
                        transform: translate(calculateWidth(-50)); }
                    img {
                        width: calculateWidth(200);
                        height: calculateWidth(200);
                        object-fit: cover;
                        @media screen and (max-width: 768px) {
                            width: tW(200);
                            height: tW(200); }
                        &:not(:last-child) {
                            margin-right: calculateWidth(50); } } } } } } }
