.main-services__content {
    display: none;
    @media screen and ( max-width: 768px ) {
        display: grid;
        padding: initial;
        h2 {
            grid-column: 1/3;
            margin-bottom: tH(50); } } }
.our-products__wrapper {
    @media screen and ( max-width: 768px ) {
        padding-top: tH(150); }
    @media screen and ( max-width: 500px ) {
        padding-top: 150px; }
    .our-products__content {
        display: grid;
        grid-template-columns: repeat( 4, 1fr );
        @media screen and ( max-width: 768px ) {
            display: none; }
        h2 {
            grid-column: 1/5; }
        ul {
            li {
                &:last-child {
                    a {
                        color: $orange;
                        background-color: initial;
                        border: 1px solid $orange;
                        position: relative;
                        align-items: center;
                        white-space: nowrap;
                        &:hover {
                            color: $white;
                            background-color: $orange; }
                        img {
                            width: calculateWidth(15);
                            height: calculateWidth(14);
                            margin-left: 5px;
                            @media screen and ( max-width: 768px ) {
                                width: tW(15);
                                height: tW(14); }
                            @media screen and ( max-width: 500px ) {
                                width: 15px;
                                height: 15px; } } } }

                a {
                    color: $dark_blue;
                    &:hover {
                        color: $orange; } } } }

        .our-products__images {
            grid-column: 2/5;
            display: grid;
            grid-template-columns: repeat( 3, 1fr );
            width: 100%;
            .our-products__image {
                display: grid;
                grid-template-columns: 1.5fr;
                grid-template-rows: 1fr 1fr;
                .our-products__image-box {
                    position: relative;
                    img {
                        max-height: calculateHeight(300);
                        max-width: calculateWidth(300); }
                    .product__img-1 {
                        position: absolute;
                        top: 0;
                        left: 50%;
                        margin-left: -25%;
                        @media screen and ( max-width: 1440px ) {
                            left: 0; } }
                    .product__img-2 {
                        position: absolute;
                        top: 0;
                        left: -50px; }
                    .product__img-3 {
                        position: absolute;
                        top: 5%;
                        right: 0; }
                    .product__img-4 {
                        position: absolute;
                        bottom: 20%;
                        right: 0; } } } } }

    .all-services__content {
        grid-template-columns: repeat(2, 50%);
        a {
            @media screen and ( max-width: 500px ) {
                min-width: initial; } }
        .subm {
            grid-column: 1/3;
            margin: 50px auto;
            white-space: nowrap;
            color: $orange;
            background-color: $white;
            border: 1px solid $orange;
            display: inline-flex;
            align-items: center;
            justify-content: space-around;
            img {
                width: 15px;
                height: 15px; } } } }
