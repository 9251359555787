.http-error {
    &__wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-image: url('../img/caution-tape.png');
        background-repeat: no-repeat;
        background-position: left bottom;
        background-size: 100%; }
    &__button {
        display: flex;
        justify-content: center; }
    &__text {
        max-width: 475px;
        margin-left: auto;
        margin-right: auto; } }


