.callback {
    &__wrapper {
        background-image: url('../img/callback__bg.png');
        background-size: cover;
        background-repeat: no-repeat; }
    &__heading {
        color: $white;
        border-left-color: $white; }
    &__text {
        color: $white;
        max-width: 525px;
        a {
            color: $orange;
            text-decoration: underline; } }
    &__form {
        height: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @media all and (max-width: 768px) {
            flex-direction: column;
            align-items: center; }
        label {
            margin: 0 calculateWidth(12.5);
            position: relative;
            @media all and (max-width: 768px) {
                margin: calculateHeight(30) calculateWidth(12.5) 0;
                .subm {
                    width: 253px; } }
            img {
                position: absolute;
                left: calculateWidth(25);
                width: calculateWidth(15);
                height: calculateWidth(14);
                top: 50%;
                margin-top: calculateWidth(-7);
                @media all and (max-width: 768px) {
                    width: 15px;
                    height: 15px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    left: tW(25); }
                @media screen and ( max-width: 500px ) {
                    left: 25px;
                    width: 15px;
                    height: 14px; } } }
        .img-reverted {
            img {
                left: unset;
                right: calculateWidth(25); } }
        .svg-arrow {
            width: 10px;
            height: 10px; }
        @media all and (min-width: 769px) {
            input {
                height: 100%; } } } }
