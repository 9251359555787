.first-section__wrapper {
    background-image: url(../img/first_bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    flex-direction: columns;
    justify-content: space-between;
    @media screen and ( max-width: 768px ) {
        height: auto; }
    .first-section__content {
        @media screen and ( max-width: 768px ) {
            padding-bottom: tH(70); }
        @media screen and ( max-width: 500px ) {
            padding-bottom: 70px; }
        .text-block {
            width: 60%;
            @media screen and ( max-width: 768px ) {
                width: 100%; }
            p {
                width: 60%; }
            a {
                display: inline-flex;
                align-items: center;
                img {
                    width: calculateWidth(15);
                    height: calculateWidth(14);
                    margin-left: 5px;
                    @media screen and ( max-width: 768px ) {
                        width: tW(15);
                        height: tW(14); }
                    @media screen and ( max-width: 500px ) {
                        width: 15px;
                        height: 15px; } } } } }
    .first-section__bottom {
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        @media screen and ( max-width: 768px ) {
            align-items: center;
            justify-content: center; }
        .social-list {
            @media screen and ( max-width: 768px ) {
                display: none; } }
        .scroll-down {
            width: calculateWidth(7);
            height: calculateHeight(50);
            animation: scrollDownShake 3s ease-in-out 2s infinite;
            &:hover {
                animation-play-state: paused; }
            @media screen and ( max-width: 768px ) {
                width: tW(7);
                height: tH(50); }
            @media screen and ( max-width: 500px ) {
                width: 7px;
                height: 50px; } }
        ul {
            @media screen and ( max-width: 768px ) {
                display: none; }
            li {
                display: flex;
                white-space: nowrap;
                justify-content: flex-start;
                align-items: center;
                &:not(:first-child) {
                    margin-top: calculateHeight(5); }
                &:hover {
                    a {
                        color: $orange; } }
                a {
                    color: $white; } } } } }

@keyframes scrollDownShake {
    0% {
        transform: translate(0, 0); }
    25% {
        transform: translate(0, 0); }
    50% {
        transform: translate(0, 10px); }
    75% {
        transform: translate(0, 0); }
    100% {
        transform: translate(0, 0); } }
